
import Vue from "vue";
import Component from "vue-class-component";
import {
  faAddressCard,
  faEllipsisV,
  faPhone,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {
  BaseProjectModel,
  ProjectsListSortModel,
  SortDirectionModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import { isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";
import { isUserCanManageProjects } from "@/services/roles/helpers";

@Component({
  computed: {
    UserPermissions() {
      return UserPermissions;
    },
  },
  methods: { isUserCanManageProjects, isUserHasPermission },
  components: {
    ConfirmationComponent,
  },
})
export default class GridComponent extends Vue {
  componentClass = "GridComponent";
  sortBy = "name";
  sortDesc = false;
  fields = [
    {
      key: "icon",
      label: "",
      sortable: false,
      tdClass: "icon-td",
      thClass: "icon-th",
    },
    {
      key: "name",
      sortable: true,
      label: "Проекты звонков",
      tdClass: "name-td",
      thClass: "name-th",
    },
    {
      key: "action",
      label: "",
      sortable: false,
      tdClass: "action-td",
      thClass: "action-th",
    },
  ];

  ellipsis: IconDefinition = faEllipsisV;
  phoneIcon: IconDefinition = faPhone;
  cardIcon: IconDefinition = faAddressCard;
  deleteProjectConfirmationShow = false;
  deletedProjectId: number | null = null;

  icons = {
    CALL: this.phoneIcon,
    CARD: this.cardIcon,
    LEAD: this.cardIcon,
  };

  async sortingChanged(ctx: SortDirectionModel) {
    this.sortBy = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;
    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST_SORT, {
      sort: ctx.sortBy,
      direction: ctx.sortDesc ? "desc" : "asc",
    });
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST);
  }

  async mounted() {
    this.sortBy = (
      this.$store.getters.getProjectsSortValue as ProjectsListSortModel
    ).sort;
    this.sortDesc = this.getSortDesc();
    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST_CLEAR);
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST);
  }

  get listItems(): BaseProjectModel[] {
    return this.$store.getters.getProjectsList;
  }

  get dataLoading(): boolean {
    return this.$store.getters.isProjectsDataLoading;
  }

  getSortDesc(): boolean {
    const direction = (
      this.$store.getters.getProjectsSortValue as ProjectsListSortModel
    ).direction;
    return direction == "desc";
  }

  handleRowClick(item: BaseProjectModel) {
    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_CLEAR);
    this.$router.push(`projects/${item.id}`);
  }

  handleDeleteItemClick(id: number) {
    this.deletedProjectId = id;
    this.deleteProjectConfirmationShow = true;
  }

  get getDeletedProjectName() {
    return this.listItems.length > 0 &&
      this.listItems.find((item) => item.id == this.deletedProjectId)
      ? this.listItems.find((item) => item.id == this.deletedProjectId).name
      : "";
  }

  get deletedProjectDisabled() {
    return this.$store.getters.isProjectsDataLoading;
  }

  async deleteProject() {
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_PROJECT_DELETE, {
      ids: [this.deletedProjectId],
    });
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST);

    this.deleteProjectCancel();
  }

  deleteProjectCancel() {
    this.deletedProjectId = null;
    this.deleteProjectConfirmationShow = false;
  }
}
