
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import {
  CallDirection,
  ReportCallModel,
  ReportCallsListSortModel,
  SortDirectionModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { i18n } from "@/i18n/index";
import * as utils from "@/services/utils";
import CallsDetails from "@/views/calls/details/CallsDetails.vue";

const IReportCallsListGridComponentProps = Vue.extend({
  props: {
    listener: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
});

@Component({
  components: {
    CallsDetails,
  },
})
export default class ReportCallsListGridComponent extends IReportCallsListGridComponentProps {
  private componentClass = "ReportCallsListGridComponent";
  private sortBy = "optionValue";
  private sortDesc = false;
  private fields = [
    {
      key: "project",
      sortable: true,
      label: i18n.t("calls.grid.project"),
      tdClass: "project-td",
      thClass: "project-th",
    },
    {
      key: "startedAt",
      sortable: true,
      label: i18n.t("calls.grid.date"),
      tdClass: "date-td",
      thClass: "date-th",
    },
    {
      key: "duration",
      sortable: true,
      label: i18n.t("calls.grid.duration"),
      tdClass: "duration-td",
      thClass: "duration-th",
    },
    {
      key: "direction",
      sortable: true,
      label: i18n.t("calls.grid.direction"),
      tdClass: "direction-td",
      thClass: "direction-th",
    },
    {
      key: "phone",
      sortable: true,
      label: i18n.t("calls.grid.phone"),
      tdClass: "phone-td",
      thClass: "phone-th",
    },
    {
      key: "estimation",
      sortable: true,
      label: i18n.t("calls.grid.estimationValue"),
      tdClass: "estimationValue-td",
      thClass: "estimationValue-th",
    },
    {
      key: "optionValue",
      sortable: true,
      label: i18n.t("calls.grid.optionValue"),
      tdClass: "optionValue-td",
      thClass: "optionValue-th",
    },
  ];

  private showCallDetails = false;

  async sortingChanged(ctx: SortDirectionModel) {
    this.sortBy = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;
    this.$store.dispatch(StoreActionTypes.SET_REPORTS_CALLS_LIST_SORT, {
      sort: ctx.sortBy,
      direction: ctx.sortDesc ? "desc" : "asc",
    });
    await this.listener();
  }

  mounted() {
    this.refresh();
  }

  refresh() {
    this.sortBy = (
      this.$store.getters
        .getReportsCallsListSortValue as ReportCallsListSortModel
    ).sort;
    this.sortDesc = this.getSortDesc();
  }

  get listItems(): ReportCallModel[] {
    return this.$store.getters.getReportsCallsList;
  }

  getSortDesc(): boolean {
    const direction = (
      this.$store.getters
        .getReportsCallsListSortValue as ReportCallsListSortModel
    ).direction;
    return direction == "desc";
  }

  async handleRowClick(item: ReportCallModel) {
    this.$store.dispatch(StoreActionTypes.SET_SELECTED_CALL_CLEAR);
    await this.$store.dispatch(StoreActionTypes.SET_SELECTED_CALL, item.id);
    this.showCallDetails = true;
  }

  getDate(date: string) {
    return date ? utils.renderDateFromString(date) : "";
  }

  getDuration(duration: number) {
    return utils.renderDuration(duration * 1000);
  }

  getDirection(direction: CallDirection) {
    return utils.getDirection(direction);
  }

  closeOverlay() {
    this.showCallDetails = false;
    this.refresh();
  }

  overlayWrapperClickHandler(event: MouseEvent) {
    event.stopPropagation();
  }
}
