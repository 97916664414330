
import Component from "vue-class-component";
import { SelectModel } from "@/services/types";
import GridItemQuantitySelect from "@/components/lib/select/GridItemQuantitySelect.vue";
import { IGridFooter } from "./IGridFooter";

@Component({
  components: {
    GridItemQuantitySelect,
  },
})
export default class GridFooter extends IGridFooter {
  public componentClass = "GridFooter";

  public pageItemQuantitySelectValues: SelectModel[] = [
    {
      value: "20",
      text: "20",
    },
    {
      value: "40",
      text: "40",
    },
    {
      value: "60",
      text: "60",
    },
    {
      value: "80",
      text: "80",
    },
  ];

  getPreviusItemsQuantity() {
    return (this.getCurrentPage - 1) * this.perPage;
  }

  getTitle() {
    return `${this.getPreviusItemsQuantity() + 1}-${
      this.getPreviusItemsQuantity() + this.perPage
    }`;
  }
}
