
import Component from "vue-class-component";
import Vue from "vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { CheckListsListFilterModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    FormInput,
    ActionButton,
  },
})
export default class GridFilter extends Vue {
  private componentClass = "GridFilter";

  private searchInputValue = "";

  mounted() {
    this.searchInputValue = (
      this.$store.getters.getCheckListsFilterValue as CheckListsListFilterModel
    ).search;
  }

  get getOutLineStatus() {
    const filtersValue = this.$store.getters
      .getCategoriesFilterValue as CheckListsListFilterModel;
    return filtersValue.search.length == 0 ? true : false;
  }

  handleSearchInputChangeEvent(text: string) {
    this.searchInputValue = text;
  }

  getCheckListsListFilterModel(): CheckListsListFilterModel {
    return {
      search: this.searchInputValue,
    };
  }

  getCheckListsListDefaultFilterModel(): CheckListsListFilterModel {
    return {
      search: "",
    };
  }

  async okBtnListener() {
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_LIST_FILTER,
      this.getCheckListsListFilterModel()
    );
    this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST);
  }

  async cancelBtnListener() {
    this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_LIST_FILTER,
      this.getCheckListsListDefaultFilterModel()
    );
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST);
    this.searchInputValue = (
      this.$store.getters.getCategoriesFilterValue as CheckListsListFilterModel
    ).search;
  }

  get showSetDefaultFiltersValueBtn(): boolean {
    return this.searchInputValue.length > 0;
  }
}
