
import Vue from "vue";
import Component from "vue-class-component";
import { faEllipsisV, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import {
  CheckListModel,
  CheckListsListSortModel,
  SortDirectionModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import { i18n } from "@/i18n/index";
import FormInput from "@/components/lib/input/FormInput.vue";
import { isUserCanManageChecklists } from "@/services/roles/helpers";

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    ModalComponent,
    ConfirmationComponent,
    FormInput,
  },
  watch: {
    "$store.getters.getCheckListsSelectedCheckList": "setEditedCheckListName",
  },
})
export default class GridComponent extends Vue {
  private componentClass = "GridComponent";
  private sortBy = "name";
  private sortDesc = false;
  private fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.t("checkLists.grid.name"),
      tdClass: "name-td",
      thClass: "name-th",
    },
    {
      key: "action",
      label: "",
      sortable: false,
      tdClass: "action-td",
      thClass: "action-th",
    },
  ];

  private ellipsis: IconDefinition = faEllipsisV;
  private deleteCheckListConfirmationShow = false;
  private selectedCheckListId: number | null = null;
  private editNameModalShow = false;
  private editedCheckListName = "";

  async sortingChanged(ctx: SortDirectionModel) {
    this.sortBy = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;
    this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST_SORT, {
      sort: ctx.sortBy,
      direction: ctx.sortDesc ? "desc" : "asc",
    });
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST);
  }

  mounted() {
    this.sortBy = (
      this.$store.getters.getCheckListsSortValue as CheckListsListSortModel
    ).sort;
    this.sortDesc = this.getSortDesc();
    this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST);
  }

  get listItems(): CheckListModel[] {
    return this.$store.getters.getCheckListsList;
  }

  getSortDesc(): boolean {
    const direction = (
      this.$store.getters.getCheckListsSortValue as CheckListsListSortModel
    ).direction;
    return direction == "desc";
  }

  handleRowClick(item: CheckListModel) {
    this.$store.dispatch(StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST_CLEAR);
    this.$router.push(`check-lists/${item.id}`);
  }

  handleDeleteItemClick(id: number) {
    this.selectedCheckListId = id;
    this.deleteCheckListConfirmationShow = true;
  }

  async handleEditNameModalOkBtnClick() {
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_CHECKLIST_CHANGE_NAME,
      this.editedCheckListName
    );
    this.editNameModalShow = !this.editNameModalShow;
  }

  async handleRenameItemClick(id: number) {
    this.selectedCheckListId = id;
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST,
      this.selectedCheckListId
    );
    this.editNameModalShow = !this.editNameModalShow;
  }

  editedCheckListNameChange(text: string) {
    this.editedCheckListName = text;
  }

  get getSelectedCheckListName() {
    return this.listItems.length > 0 &&
      this.listItems.find((item) => item.id == this.selectedCheckListId)
      ? this.listItems.find((item) => item.id == this.selectedCheckListId).name
      : "";
  }

  get selectedCheckListDisabled() {
    return this.$store.getters.isCheckListsDataLoading;
  }

  async deleteCheckList() {
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_CHECKLIST_DELETE, {
      ids: [this.selectedCheckListId],
    });

    this.deleteCheckListCancel();
  }

  deleteCheckListCancel() {
    this.selectedCheckListId = null;
    this.deleteCheckListConfirmationShow = false;
  }

  setEditedCheckListName() {
    this.editedCheckListName = (this.$store.getters
      .getCheckListsSelectedCheckList as CheckListModel)
      ? (this.$store.getters.getCheckListsSelectedCheckList as CheckListModel)
          .name
      : "";
  }
}
