
import Component from "vue-class-component";
import Vue from "vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { ProjectsListFilterModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { ProjectType } from "@/services/types/ProjectType";

@Component({
  components: {
    FormInput,
    ActionButton,
  },
})
export default class GridFilter extends Vue {
  componentClass = "GridFilter";

  searchInputValue = "";
  selectedTypeValue: string | null = null;

  projectTypes = ProjectType;

  get getOutLineStatus() {
    const filtersValue = this.$store.getters
      .getProjectsFilterValue as ProjectsListFilterModel;
    return filtersValue.search.length == 0 ? true : false;
  }

  handleSearchInputChangeEvent(text: string) {
    this.searchInputValue = text;
  }

  selectTypeValue(type: string) {
    this.selectedTypeValue = type;
  }

  getProjectsListFilterModel(): ProjectsListFilterModel {
    return {
      search: this.searchInputValue,
      ...(this.selectedTypeValue && { type: this.selectedTypeValue }),
    };
  }

  getProjectsListDefaultFilterModel(): ProjectsListFilterModel {
    return {
      search: "",
    };
  }

  async applyBtnListener() {
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_LIST_FILTER,
      this.getProjectsListFilterModel()
    );
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST);
  }

  async cancelBtnListener() {
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_LIST_FILTER,
      this.getProjectsListDefaultFilterModel()
    );
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST);
    this.searchInputValue = (
      this.$store.getters.getProjectsFilterValue as ProjectsListFilterModel
    ).search;
  }

  get showSetDefaultFiltersValueBtn(): boolean {
    return this.searchInputValue.length > 0;
  }
}
