
import Vue from "vue";
import Component from "vue-class-component";
import DraggableElement from "./DraggableElement.vue";
import draggable from "vuedraggable";
import { StoreActionTypes } from "@/store/types";
import { CreateCriterionRequestModel } from "@/services/types";
import CreateCriterionModalForm from "@/components/form/CreateCriterionModalForm.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import EditCheckListModalForm from "@/components/form/editCheckList/EditCheckListModalForm.vue";

const INestedDraggableProps = Vue.extend({
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
    nestedList: {
      type: Array,
      required: true,
    },
    groupName: {
      type: String,
    },
    isEdit: {
      type: Boolean,
    },
    checkListId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  name: "NestedDraggable",
  components: {
    draggable,
    DraggableElement,
    ModalComponent,
    CreateCriterionModalForm,
    EditCheckListModalForm,
  },
})
export default class NestedDraggable extends INestedDraggableProps {
  private showCreateNewCriterionForm = false;
  private showEditCheckListForm = false;

  public get realValue() {
    return this.value ? this.value : this.list;
  }

  get createNewCriterionFormDisabled() {
    return this.$store.getters.isCriterionsDataLoading;
  }

  emitter(value) {
    this.$emit("input", value);
  }

  handleCreateNewCriterionBtnClick() {
    this.showCreateNewCriterionForm = !this.showCreateNewCriterionForm;
  }

  async handleCreateNewCriterionModalOkBtnClick() {
    const model: CreateCriterionRequestModel = (
      this.$refs.createNewCriterionModalForm as CreateCriterionModalForm
    ).getCreateCriterionRequestModel();
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_CREATE,
      model
    );
    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);
    this.handleCreateNewCriterionBtnClick();
  }

  get editCheckListFormDisabled() {
    return this.$store.getters.isCheckListsDataLoading;
  }

  async handleEditCheckListModalOkBtnClick() {
    const ids: number[] = (
      this.$refs.editCheckListModalForm as EditCheckListModalForm
    ).getCriterionsList();
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST,
      { ids }
    );
    this.showEditCheckListForm = false;
  }
}
