
import Component from "vue-class-component";
import Vue from "vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import SelectStatusFilter from "@/components/select/status/SelectStatusFilter.vue";
import {
  CriterionsListFilterModel,
  CriterionTypeCode,
  StatusType,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import SelectCriterionTypeFilter from "@/components/select/criterionType/SelectCriterionTypeFilter.vue";

@Component({
  components: {
    FormInput,
    ActionButton,
    SelectStatusFilter,
    SelectCriterionTypeFilter,
  },
})
export default class GridFilter extends Vue {
  private componentClass = "GridFilter";

  private searchInputValue = "";
  private statusSelectValue = "null";
  private criterionTypeSelectValue = "null";

  mounted() {
    this.searchInputValue = (
      this.$store.getters.getCriterionsFilterValue as CriterionsListFilterModel
    ).search;
  }

  handleSearchInputChangeEvent(text: string) {
    this.searchInputValue = text;
  }

  handleStatusSelectChangeEvent(selected: string) {
    this.statusSelectValue = selected;
  }

  handleCriterionTypeSelectChangeEvent(selected: string) {
    this.criterionTypeSelectValue = selected;
  }

  getStatusSelectValue(): StatusType | null {
    if (this.statusSelectValue == "ALL" || this.statusSelectValue == "null") {
      return null;
    } else {
      return this.statusSelectValue as StatusType;
    }
  }

  getCriterionTypeSelectValue(): CriterionTypeCode | null {
    if (
      this.criterionTypeSelectValue == "ALL" ||
      this.criterionTypeSelectValue == "null"
    ) {
      return null;
    } else {
      return this.criterionTypeSelectValue as CriterionTypeCode;
    }
  }

  getCriterionsListFilterModel(): CriterionsListFilterModel {
    return {
      search: this.searchInputValue,
      status: this.getStatusSelectValue(),
      typeCode: this.getCriterionTypeSelectValue(),
    };
  }

  getCriterionsListDefaultFilterModel(): CriterionsListFilterModel {
    return {
      search: "",
      status: null,
      typeCode: null,
    };
  }

  okBtnListener() {
    // console.log(this.getCriterionsListFilterModel())
    this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_LIST_FILTER,
      this.getCriterionsListFilterModel()
    );
  }

  cancelBtnListener() {
    this.searchInputValue = "";
    this.statusSelectValue = "null";
    this.criterionTypeSelectValue = "null";
    this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_LIST_FILTER,
      this.getCriterionsListDefaultFilterModel()
    );
  }

  get showSetDefaultFiltersValueBtn(): boolean {
    return (
      this.searchInputValue.length > 0 ||
      this.statusSelectValue != "null" ||
      this.criterionTypeSelectValue != "null"
    );
  }

  get getOutLineStatus() {
    const filtersValue = this.$store.getters
      .getCriterionsFilterValue as CriterionsListFilterModel;
    return filtersValue.search.length == 0 && filtersValue.status == null
      ? true
      : false;
  }
}
