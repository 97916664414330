
import Vue from "vue";
import Component from "vue-class-component";
import NestedDraggable from "@/components/grids/checkListsList/list/NestedDraggable.vue";
import { StoreMutationTypes } from "@/store/types";

const DraggableTableProps = Vue.extend({
  props: {
    list: {
      type: Array,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    checkListId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  components: {
    NestedDraggable,
  },
})
export default class DraggableTable extends DraggableTableProps {
  private componentClass = "DraggableTable";

  public get realList() {
    return this.list ? this.list : null;
  }

  public set realList(value) {
    this.$emit("edit", value);
  }

  get isCheckListsDataLoading() {
    return this.$store.getters.isCheckListsDataLoading;
  }
}
