
declare const window: any;
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import EditCriterionModalForm from "@/components/form/EditCriterionModalForm.vue";
import EditCriterionGroupModalForm from "@/components/form/EditCriterionGroupModalForm.vue";
import { EditCriterionRequestModel } from "@/services/types";

const IDraggableElementProps = Vue.extend({
  props: {
    element: {
      required: true,
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    checkListId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
    nestedList: {
      type: Array,
      required: true,
    },
  },
});

@Component({
  components: {
    ModalComponent,
    EditCriterionModalForm,
    EditCriterionGroupModalForm,
  },
})
export default class DraggableElement extends IDraggableElementProps {
  private componentClass = "DraggableElement";
  private showEditCheckListForm = false;
  private showEditCriterionForm = false;
  private showEditGroupForm = true;

  get editedCriterionDisabled() {
    return this.$store.getters.isCriterionsDataLoading;
  }

  handleElementClick() {
    if (!this.disabled) {
      return;
    }
    if (this.element.type === "group") {
      this.handleEditGroupBtnClick();
    } else {
      this.handleEditCriterionBtnClick();
    }
  }

  swithPopover() {
    (this.$refs.editCriterionForm as any).$emit("open");
  }

  handleEditCriterionBtnClick() {
    this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_SELECTED_CRITERION_CLEAR
    );
    this.showEditCriterionForm = !this.showEditCriterionForm;
  }

  get sortedOptions() {
    return this.element.options
      ? this.element.options.sort((a, b) => b.nominalWeight - a.nominalWeight)
      : null;
  }

  handleHideCriterionModalBtnClick() {
    this.showEditCriterionForm = !this.showEditCriterionForm;
  }

  async removeCriterionBtnClick() {
    let ids = this.nestedList
      .map((v: any) => {
        return v.criterions.map((t) => t.id);
      })
      .flat();

    const thisIds = [this.element.id];

    ids = ids.filter((val) => !thisIds.includes(val));
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST,
      { ids }
    );
  }

  async hideCriterionBtnClick() {
    const model = {
      ...this.element,
      status: "INACTIVE",
    };

    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_EDIT,
      model
    );
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      this.checkListId
    );
    this.handleEditCriterionBtnClick();
  }

  handleEditGroupBtnClick() {
    //this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_CLEAR)
    this.showEditGroupForm = !this.showEditGroupForm;
  }

  async handleEditCriterionModalOkBtnClick() {
    const model: EditCriterionRequestModel = (
      this.$refs.editCriterionForm as EditCriterionModalForm
    ).getEditCriterionRequestModel();
    const optionsNotFilled = model.options.find(
      (v) => v.name.length === 0 || v.nominalWeight === null
    );
    if (
      model.name.length === 0 ||
      model.categoryId === 0 ||
      model.options.length === 0 ||
      optionsNotFilled
    ) {
      window.error(
        "Проверьте правильность заполненных полей",
        "Ошибка редактирования критерия"
      );
      return;
    }
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_EDIT,
      model
    );
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      this.checkListId
    );
    this.handleEditCriterionBtnClick();
  }

  async handleEditCriterionGroupModalOkBtnClick() {
    const model = (
      this.$refs.editCriterionGroupForm as EditCriterionGroupModalForm
    ).getEditCriterionGroupRequestModel();
    if (model.name.length < 5) {
      window.error(
        "Имя группы не может быть пустым или содержать менее 5 символов",
        "Ошибка редактирования группы"
      );
      return;
    }
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_SELECTED_CATEGORY,
      this.element.id
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_CHANGE_STATUS,
      model
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_CHANGE,
      model
    );
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      this.checkListId
    );

    //this.element.name = model.name;
    //this.element.status = model.status;
    this.handleEditGroupBtnClick();
  }
}
