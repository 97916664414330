
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import ModalComponent from "@/components/modal/ModalComponent.vue";

const ConfirmModalProps = Vue.extend({});

@Component({
  name: "ConfirmModal",
  components: {
    ModalComponent,
  },
})
export default class ConfirmModal extends ConfirmModalProps {
  public componentClass = "ConfirmModal";
  public get modal() {
    return this.$store.getters.getConfirmMoal;
  }
  actionFire() {
    this.modal.action();
    this.close();
  }

  close() {
    this.$store.dispatch(StoreActionTypes.SET_CONFIRM_MODAL, null);
  }
}
