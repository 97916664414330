
import Component from "vue-class-component";
import Vue from "vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import SelectStatusFilter from "@/components/select/status/SelectStatusFilter.vue";
import {
  CategoriesListFilterModel,
  SelectModel,
  StatusType,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    FormInput,
    ActionButton,
    SelectStatusFilter,
  },
})
export default class GridFilter extends Vue {
  private componentClass = "GridFilter";

  private searchInputValue = "";
  private statusSelectValue = "null";

  private statusSelectOptions: SelectModel[] = [
    {
      value: "true",
      text: "Активна",
    },
    {
      value: "false",
      text: "Деактивирована",
    },
  ];

  mounted() {
    this.searchInputValue = (
      this.$store.getters.getCategoriesFilterValue as CategoriesListFilterModel
    ).search;

    this.setStatusSelectedValue();
  }

  get getOutLineStatus() {
    const filtersValue = this.$store.getters
      .getCategoriesFilterValue as CategoriesListFilterModel;
    return filtersValue.search.length == 0 && filtersValue.status == null
      ? true
      : false;
  }

  setStatusSelectedValue() {
    let statusValue: StatusType | string;
    if (
      (
        this.$store.getters
          .getCategoriesFilterValue as CategoriesListFilterModel
      ).status == null
    ) {
      statusValue = "null";
    } else {
      statusValue = (
        this.$store.getters
          .getCategoriesFilterValue as CategoriesListFilterModel
      ).status;
    }

    this.statusSelectValue = statusValue;
  }

  handleSearchInputChangeEvent(text: string) {
    this.searchInputValue = text;
  }

  handleStatusSelectChangeEvent(selected: string) {
    this.statusSelectValue = selected;
  }

  getStatusSelectValue(): StatusType | null {
    if (this.statusSelectValue == "null" || this.statusSelectValue == "ALL") {
      return null;
    } else {
      return this.statusSelectValue as StatusType;
    }
  }

  getCategoriesListFilterModel(): CategoriesListFilterModel {
    return {
      search: this.searchInputValue,
      status: this.getStatusSelectValue(),
    };
  }

  getCategoriesListDefaultFilterModel(): CategoriesListFilterModel {
    return {
      search: "",
      status: null,
    };
  }

  async okBtnListener() {
    this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_LIST_FILTER,
      this.getCategoriesListFilterModel()
    );
    await this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST);
  }

  async cancelBtnListener() {
    this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_LIST_FILTER,
      this.getCategoriesListDefaultFilterModel()
    );
    await this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST);
    this.searchInputValue = (
      this.$store.getters.getCategoriesFilterValue as CategoriesListFilterModel
    ).search;
    this.setStatusSelectedValue();
  }

  get showSetDefaultFiltersValueBtn(): boolean {
    return this.searchInputValue.length > 0 || this.statusSelectValue != "null";
  }
}
