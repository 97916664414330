
import { isUserCanManageChecklists } from "@/services/roles/helpers";

declare const window: any;
import Vue from "vue";
import Component from "vue-class-component";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import {
  ColorTheme,
  CriterionModel,
  CriterionTypeCode,
  StatusType,
  SortDirectionModel,
  CriterionsListSortModel,
  EditCriterionRequestModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { IconDefinition, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import * as utils from "@/services/utils";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import EditCriterionModalForm from "@/components/form/EditCriterionModalForm.vue";
import { i18n } from "@/i18n/index";

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    ModalComponent,
    ConfirmationComponent,
    EditCriterionModalForm,
  },
})
export default class GridComponent extends Vue {
  private componentClass = "GridComponent";

  private sortBy = "name";
  private sortDesc = false;

  private fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.t("criterions.grid.name"),
      tdClass: "name-td",
      thClass: "name-th",
    },
    { key: "typeCode", sortable: true, label: i18n.t("criterions.grid.type") },
    {
      key: "status",
      sortable: true,
      label: i18n.t("criterions.grid.status"),
      tdClass: "status-td",
      thClass: "status-th",
    },
    {
      key: "action",
      label: "",
      sortable: false,
      tdClass: "action-td",
      thClass: "action-th",
    },
  ];

  private showEditCriterionForm = false;
  private ellipsis: IconDefinition = faEllipsisV;
  private selectedCriterionId = null;

  private deleteCriterionConfirmationShow = false;
  private changeStatusCriterionConfirmationShow = false;
  private editedCriterionId: number | null = null;

  mounted() {
    this.sortBy = (
      this.$store.getters.getCriterionsSortValue as CriterionsListSortModel
    ).sort;
    this.sortDesc = this.getSortDesc();
    this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);
  }

  get listItems(): CriterionModel[] {
    return this.$store.getters.getCriterionsList;
  }

  getStatus(status: StatusType) {
    return status == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.title.active")
      : this.$i18n.t("criterionsDetails.title.deactive");
  }

  getTypeCode(code: CriterionTypeCode) {
    return utils.getCriterionTypeCode(code);
  }

  getChangeStatusItemTitle(status: StatusType) {
    return status == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.item.deactivate")
      : this.$i18n.t("criterionsDetails.item.activate");
  }

  handleEditCriterionBtnClick(id: number) {
    this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_SELECTED_CRITERION_CLEAR
    );
    this.selectedCriterionId = id;
    this.showEditCriterionForm = !this.showEditCriterionForm;
  }

  async handleEditCriterionModalOkBtnClick() {
    const model: EditCriterionRequestModel = (
      this.$refs.editCriterionForm as EditCriterionModalForm
    ).getEditCriterionRequestModel();
    const optionsNotFilled = model.options.find(
      (v) => v.name.length === 0 || v.nominalWeight === null
    );
    if (
      model.name.length === 0 ||
      model.categoryId === 0 ||
      model.options.length === 0 ||
      optionsNotFilled
    ) {
      window.error(
        "Проверьте правильность заполненных полей",
        "Ошибка редактирования критерия"
      );
      return;
    }
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_EDIT,
      model
    );
    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST, model);
    this.handleEditCriterionBtnClick(null);
  }

  get editedCriterionDisabled() {
    return this.$store.getters.isCriterionsDataLoading;
  }

  get getCriterionName() {
    if (this.listItems.length > 0 && this.selectedCriterionId) {
      return this.listItems.find((item) => item.id == this.selectedCriterionId)
        .name;
    } else {
      return "";
    }
  }

  handleChangeStatusItemClick(id: number) {
    this.editedCriterionId = id;
    this.changeStatusCriterionConfirmationShow = true;
  }

  handleDeleteItemClick(id: number) {
    this.editedCriterionId = id;
    this.deleteCriterionConfirmationShow = true;
  }

  get getEditedCriterionName() {
    return this.listItems.length > 0 &&
      this.listItems.find((item) => item.id == this.editedCriterionId)
      ? this.listItems.find((item) => item.id == this.editedCriterionId).name
      : "";
  }

  async deleteCriterion() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_DELETE,
      {
        ids: [this.editedCriterionId],
      }
    );

    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);

    this.deleteCriterionCancel();
  }

  deleteCriterionCancel() {
    this.editedCriterionId = null;
    this.deleteCriterionConfirmationShow = false;
  }

  changeCriterionStatusCancel() {
    this.editedCriterionId = null;
    this.changeStatusCriterionConfirmationShow = false;
  }

  getEditedCriterionStatus() {
    return this.listItems.length > 0 &&
      this.listItems.find((item) => item.id == this.editedCriterionId)
      ? this.listItems.find((item) => item.id == this.editedCriterionId).status
      : null;
  }

  async changeCriterionStatus() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_CHANGE_STATUS,
      {
        id: this.editedCriterionId,
        status: this.getEditedCriterionStatus(),
      }
    );

    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);

    this.changeCriterionStatusCancel();
  }

  getOkBtnVariant(status: StatusType): ColorTheme {
    return status == "ACTIVE" ? "danger" : "success";
  }

  async sortingChanged(ctx: SortDirectionModel) {
    this.sortBy = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;
    this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST_SORT, {
      sort: ctx.sortBy,
      direction: ctx.sortDesc ? "desc" : "asc",
    });
    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);
  }

  getSortDesc(): boolean {
    const direction = (
      this.$store.getters.getCriterionsSortValue as CriterionsListSortModel
    ).direction;
    return direction == "desc";
  }
}
