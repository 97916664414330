
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import { StoreActionTypes } from "@/store/types";
import { i18n } from "@/i18n/index";
import { BaseReportRenderModel, ReportsPageType } from "@/services/types";

export const ReportGridComponent = Vue.extend({
  props: {
    gridReportTitle: {
      type: String,
      required: true,
    },
    setNameClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowClickListener: {
      type: Function as PropType<(id: number) => void>,
      required: false,
    },
  },
});

@Component
export default class GridComponent extends ReportGridComponent {
  private componentClass = "GridComponent";

  private sortBy = "rating";
  private sortDesc = true;

  private fields = [
    {
      key: "action",
      sortable: false,
      label: "",
      tdClass: "action-td",
      thClass: "action-th",
    },
    {
      key: "color",
      sortable: false,
      label: "",
      tdClass: "color-td",
      thClass: "color-th",
    },
    {
      key: "report",
      sortable: true,
      label: this.gridReportTitle,
      tdClass: "name-td",
      thClass: "name-th",
    },
    {
      key: "rating",
      sortable: true,
      label: i18n.t("reports.grid.managers.rating"),
      tdClass: "rating-td",
      thClass: "rating-th",
    },
  ];

  get listItems() {
    return this.$store.getters.getReportsRenderList as BaseReportRenderModel[];
  }

  handleRowCheckboxClick(recordId: number) {
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_HANDLE_LIST_ITEM_CLICK,
      recordId
    );
  }

  handleRowCheckboxWrapperClick(recordId: number) {
    this.handleRowCheckboxClick(recordId);
  }

  handleNameClick(item: BaseReportRenderModel) {
    const page: ReportsPageType = this.$store.getters.getReportsPage;
    this.$store.dispatch(StoreActionTypes.SET_REPORTS_LIST_CLEAR);
    const path = page == "managers" ? "reports/managers" : "reports/criterions";
    this.$router.push(`${path}/${item.id}`);
  }

  async handleRowClick(item: BaseReportRenderModel) {
    if (!this.setNameClickable) {
      await this.rowClickListener(item.id);
    } else {
      this.handleNameClick(item);
    }
  }
}
