
import Vue from "vue";
import Component from "vue-class-component";
import { CheckListModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { i18n } from "@/i18n/index";

@Component
export default class ProjectCheckListGridComponent extends Vue {
  private componentClass = "ProjectCheckListGridComponent";
  private sortBy = "name";
  private sortDesc = false;
  private fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.t("checkLists.grid.name"),
      tdClass: "name-td",
      thClass: "name-th",
    },
    {
      key: "action",
      label: "",
      sortable: false,
      tdClass: "action-td",
      thClass: "action-th",
    },
  ];

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST_GET
    );
  }

  get listItems(): CheckListModel[] {
    return this.$store.getters.getProjectsSelectedProjectCheckList;
  }
}
