var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.componentClass},[_c('div',{class:`${_vm.componentClass}__date-filter`},[_c('div',{class:`${_vm.componentClass}__date-filter-item`},[_c('b-form-datepicker',{class:{ active: !!_vm.dateFilterValues.from },attrs:{"locale":"ru","date-format-options":{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        },"start-weekday":"1"},on:{"input":_vm.handleDatePickerChange},model:{value:(_vm.dateFilterValues.from),callback:function ($$v) {_vm.$set(_vm.dateFilterValues, "from", $$v)},expression:"dateFilterValues.from"}})],1),_c('div',{class:`${_vm.componentClass}__date-filter-item`},[_c('div',{class:`${_vm.componentClass}__date-filter-divider`},[_c('font-awesome-icon',{staticClass:"dividerIcon",attrs:{"icon":_vm.dividerIcon}})],1)]),_c('div',{class:`${_vm.componentClass}__date-filter-item`},[_c('b-form-datepicker',{class:{ active: !!_vm.dateFilterValues.to },attrs:{"locale":"ru","date-format-options":{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        },"start-weekday":"1"},on:{"input":_vm.handleDatePickerChange},model:{value:(_vm.dateFilterValues.to),callback:function ($$v) {_vm.$set(_vm.dateFilterValues, "to", $$v)},expression:"dateFilterValues.to"}})],1)]),_c('SelectDateInterval',{attrs:{"value":_vm.selectDateIntervalValue,"onSelect":_vm.handleSelectDateIntervalChange}}),_c('SelectProjectsMultiple',{attrs:{"selectedItems":_vm.projectsSelectedItems,"onSelect":_vm.handleProjectsSelectEvent,"itemsList":_vm.projectsList}}),(_vm.showSetDefaultFiltersValueBtn)?_c('div',{staticClass:"divider"}):_vm._e(),(_vm.showSetDefaultFiltersValueBtn)?_c('ActionButton',{attrs:{"variant":"link","listener":_vm.cancelBtnListener}},[_vm._v(_vm._s(_vm.$t("actions.clear")))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }