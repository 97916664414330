
declare const window: any;
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";

const NotificationProps = Vue.extend({});

@Component({
  components: {},
})
export default class Notification extends NotificationProps {
  mounted() {
    window.info = (text, title) => window.notify(text, false, title);
    window.error = (text, title) => window.notify(text, true, title);

    window.notify = (text, error = false, title = "") => {
      const obj = {
        id: Math.random(),
        title,
        text,
        error,
        show: false,
      };

      setTimeout(() => (obj.show = true), 100);
      setTimeout(() => (obj.show = false), 5000);

      console[error ? "error" : "log"](text);

      this.notifications.push(obj);
    };
  }

  public notifications = [];
}
