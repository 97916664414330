
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";

const NotFoundProps = Vue.extend({});

@Component({
  components: {},
})
export default class NotFound extends NotFoundProps {
  openPage(page: string) {
    this.$router.push(page);
  }
}
