
import Vue from "vue";
import Component from "vue-class-component";
import { IconDefinition, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ProjectIntegration, StatusType } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import { i18n } from "@/i18n";
import * as utils from "@/services/utils";

@Component({
  components: {
    ConfirmationComponent,
  },
})
export default class ProjectIntegrationsGridComponent extends Vue {
  private componentClass = "ProjectIntegrationsGridComponent";
  private sortBy = "name";
  private sortDesc = false;
  private fields = [
    {
      key: "type",
      sortable: true,
      label: i18n.t("projects.integrations.grid.type"),
      tdClass: "type-td",
      thClass: "type-th",
    },
    {
      key: "status",
      sortable: true,
      label: i18n.t("projects.integrations.grid.status"),
      tdClass: "status-td",
      thClass: "status-th",
    },
    {
      key: "createdAt",
      sortable: true,
      label: i18n.t("projects.integrations.grid.date"),
      tdClass: "date-td",
      thClass: "date-th",
    },
    {
      key: "callDateFrom",
      sortable: true,
      label: i18n.t("projects.integrations.grid.callDateFrom"),
      tdClass: "callDateFrom-td",
      thClass: "callDateFrom-th",
    },
    {
      key: "callDateTo",
      sortable: true,
      label: i18n.t("projects.integrations.grid.callDateTo"),
      tdClass: "callDateTo-td",
      thClass: "callDateTo-th",
    },
    {
      key: "durationMin",
      sortable: true,
      label: i18n.t("projects.integrations.grid.durationMin"),
      tdClass: "durationMin-td",
      thClass: "durationMin-th",
    },
    {
      key: "durationMax",
      sortable: true,
      label: i18n.t("projects.integrations.grid.durationMax"),
      tdClass: "durationMax-td",
      thClass: "durationMax-th",
    },
    {
      key: "action",
      label: "",
      sortable: false,
      tdClass: "action-td",
      thClass: "action-th",
    },
  ];

  private ellipsis: IconDefinition = faEllipsisV;
  private deleteIntegrationConfirmationShow = false;
  private changeIntegrationStatusConfirmationShow = false;
  private selectedIntegrationId: number | null = null;

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_GET
    );
  }

  get getTotal(): number {
    return this.$store.getters.getCallsTotal;
  }

  get listItems(): ProjectIntegration[] {
    return this.$store.getters.getProjectIntegrationIntegrationsList;
  }

  handleDeleteItemClick(id: number) {
    this.selectedIntegrationId = id;
    this.deleteIntegrationConfirmationShow = true;
  }

  handleRowClick(row: ProjectIntegration) {
    this.handleEditItemClick(row.id);
  }

  handleEditItemClick(id: number) {
    const projectId = this.$store.getters.getProjectsSelectedProject.id;
    this.$router.push(`/projects/${projectId}/integrations/${id}`);
  }

  get getSelectedIntegrationName() {
    return this.listItems.length > 0 &&
      this.listItems.find((item) => item.id == this.selectedIntegrationId)
      ? this.listItems.find((item) => item.id == this.selectedIntegrationId)
          .type
      : "";
  }

  get selectedIntegrationDisabled() {
    return this.$store.getters.isProjectsDataLoading;
  }

  async deleteIntegration() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_DELETE,
      { ids: [this.selectedIntegrationId] }
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_GET
    );

    this.deleteIntegrationCancel();
  }

  deleteIntegrationCancel() {
    this.selectedIntegrationId = null;
    this.deleteIntegrationConfirmationShow = false;
  }

  getStatusTitle(status: StatusType) {
    return status == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.title.active")
      : this.$i18n.t("criterionsDetails.title.deactive");
  }

  getChangeStatusItemTitle(status: StatusType) {
    return status == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.item.deactivate")
      : this.$i18n.t("criterionsDetails.item.activate");
  }

  handleChangeStatusItemClick(id: number) {
    this.selectedIntegrationId = id;
    this.changeIntegrationStatusConfirmationShow = true;
  }

  async changeIntegrationStatus() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_GET,
      this.selectedIntegrationId
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CHANGE_STATUS
    );

    this.changeIntegrationStatusCancel();
  }

  changeIntegrationStatusCancel() {
    this.selectedIntegrationId = null;
    this.changeIntegrationStatusConfirmationShow = false;
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_CLEAR
    );
  }

  getDate(date: string) {
    return date ? utils.renderDateFromString(date) : "";
  }
}
