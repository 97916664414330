
import { isUserCanManageChecklists } from "@/services/roles/helpers";

declare const window: any;
import Vue from "vue";
import Component from "vue-class-component";
import { IconDefinition, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  CategoryModel,
  StatusType,
  CategoriesListSortModel,
  ColorTheme,
  SortDirectionModel,
  CreateCriterionRequestModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import CreateCriterionModalForm from "@/components/form/CreateCriterionModalForm.vue";
import { i18n } from "@/i18n/index";

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    ModalComponent,
    ConfirmationComponent,
    CreateCriterionModalForm,
  },
})
export default class GridComponent extends Vue {
  private componentClass = "GridComponent";
  private sortBy = "name";
  private sortDesc = false;
  private fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.t("criterionsGroups.grid.name"),
      tdClass: "name-td",
      thClass: "name-th",
    },
    {
      key: "status",
      sortable: true,
      label: i18n.t("criterionsGroups.grid.status"),
      tdClass: "status-td",
      thClass: "status-th",
    },
    {
      key: "action",
      label: "",
      sortable: false,
      tdClass: "action-td",
      thClass: "action-th",
    },
  ];

  private ellipsis: IconDefinition = faEllipsisV;
  private modalShow = false;
  private selectedCategoryId = null;
  private deleteCategoryConfirmationShow = false;
  private changeStatusCategoryConfirmationShow = false;
  private editedCategoryId: number | null = null;

  async sortingChanged(ctx: SortDirectionModel) {
    this.sortBy = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;
    this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST_SORT, {
      sort: ctx.sortBy,
      direction: ctx.sortDesc ? "desc" : "asc",
    });
    await this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST);
  }

  mounted() {
    this.sortBy = (
      this.$store.getters.getCategoriesSortValue as CategoriesListSortModel
    ).sort;
    this.sortDesc = this.getSortDesc();
    this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST);
  }

  get listItems(): CategoryModel[] {
    return this.$store.getters.getCategoriesList;
  }

  getSortDesc(): boolean {
    const direction = (
      this.$store.getters.getCategoriesSortValue as CategoriesListSortModel
    ).direction;
    return direction == "desc";
  }

  getStatusTitle(status: StatusType) {
    return status == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.title.active")
      : this.$i18n.t("criterionsDetails.title.deactive");
  }

  getChangeStatusItemTitle(status: StatusType) {
    return status == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.item.deactivate")
      : this.$i18n.t("criterionsDetails.item.activate");
  }

  handleChangeStatusItemClick(id: number) {
    this.editedCategoryId = id;
    this.changeStatusCategoryConfirmationShow = true;
  }

  async handleModalOkBtnClick() {
    const model: CreateCriterionRequestModel = (
      this.$refs.createNewCriterionModalForm as CreateCriterionModalForm
    ).getCreateCriterionRequestModel();
    const optionsNotFilled = model.options.find(
      (v) => v.name.length === 0 || v.nominalWeight === null
    );
    if (
      model.name.length === 0 ||
      model.categoryId === 0 ||
      model.options.length === 0 ||
      optionsNotFilled
    ) {
      window.error(
        "Проверьте правильность заполненных полей",
        "Ошибка создания критерия"
      );
      return;
    }
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_SELECTED_CATEGORY,
      this.selectedCategoryId
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_CREATE,
      model
    );
    this.$router.push(`criterions-groups/${this.selectedCategoryId}`);
  }

  async handleCreateNewCriterionBtnClick(id: number) {
    this.selectedCategoryId = id;
    this.modalShow = !this.modalShow;
  }

  get createNewCriterionFormDisabled() {
    return this.$store.getters.isCriterionsDataLoading;
  }

  get getModalTitle() {
    return this.selectedCategoryId
      ? this.listItems.find((item) => item.id == this.selectedCategoryId).name
      : "";
  }

  handleRowClick(item: CategoryModel) {
    this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_CLEAR
    );
    this.$router.push(`criterions-groups/${item.id}`);
  }

  handleDeleteItemClick(id: number) {
    this.editedCategoryId = id;
    this.deleteCategoryConfirmationShow = true;
  }

  get getEditedCategoryName() {
    return this.listItems.length > 0 &&
      this.listItems.find((item) => item.id == this.editedCategoryId)
      ? this.listItems.find((item) => item.id == this.editedCategoryId).name
      : "";
  }

  get editedCategoryDisabled() {
    return this.$store.getters.isCategoriesDataLoading;
  }

  async deleteCategory() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_DELETE,
      {
        ids: [this.editedCategoryId],
      }
    );

    this.deleteCategoryCancel();
  }

  deleteCategoryCancel() {
    this.editedCategoryId = null;
    this.deleteCategoryConfirmationShow = false;
  }

  changeCategoryStatusCancel() {
    this.editedCategoryId = null;
    this.changeStatusCategoryConfirmationShow = false;
  }

  getEditedCategoryStatus() {
    return this.listItems.length > 0 &&
      this.listItems.find((item) => item.id == this.editedCategoryId)
      ? this.listItems.find((item) => item.id == this.editedCategoryId).status
      : null;
  }

  get getEditedCategoryOkTitle() {
    return this.getChangeStatusItemTitle(this.getEditedCategoryStatus());
  }

  get setChangeStatusConfirmationOkBtnVariant() {
    return this.getOkBtnVariant(this.getEditedCategoryStatus());
  }

  getOkBtnVariant(status: StatusType): ColorTheme {
    return status == "ACTIVE" ? "danger" : "success";
  }

  async changeCategoryStatus() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_CHANGE_STATUS,
      {
        id: this.editedCategoryId,
        status: this.getEditedCategoryStatus(),
      }
    );
    this.changeCategoryStatusCancel();
  }
}
