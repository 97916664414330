
import Vue from "vue";
import Component from "vue-class-component";
import {
  faCheck,
  faEllipsisV,
  faXmark,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import {
  BaseCallModel,
  BaseEntityModel,
  CallCriterionEstimateModel,
  CallDirection,
  CallModel,
  CallsListSortModel,
  SortDirectionModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { i18n } from "@/i18n/index";
import * as utils from "@/services/utils";
import CallsDetails from "@/views/calls/details/CallsDetails.vue";
import EmptyDataMessage from "@/components/emptyData/EmptyDataMessage.vue";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import router from "@/router";
import { CallEntityModel } from "@/services/types/entities/entities";
import { getStore } from "@/store/getStore";
import {SttState} from "@/features/call2text/SttState";

@Component({
  computed: {
    SttState() {
      return SttState
    }
  },
  methods: {
    faXmark() {
      return faXmark;
    },
    faCheck() {
      return faCheck;
    },
  },
  components: {
    CallsDetails,
    EmptyDataMessage,
    ConfirmationComponent,
  },
  watch: {
    "$store.getters.getCallsSelectedGridColumns":
      "handleCallsSelectedGridColumnsSelectChange",
    "$store.getters.isCallsDataLoading": "handleCallsDataLoading",
    "$store.getters.getSelectedCall": "syncSelectedCallId",
  },
})
export default class GridComponent extends Vue {
  private componentClass = "GridComponent";
  private sortBy = "name";
  private sortDesc = false;
  private fields = [
    {
      key: "project",
      sortable: true,
      label: i18n.t("calls.grid.project"),
      tdClass: "project-td",
      thClass: "project-th",
    },
    {
      key: "manager",
      sortable: true,
      label: i18n.t("calls.grid.manager"),
      tdClass: "manager-td",
      thClass: "manager-th",
    },
    {
      key: "startedAt",
      sortable: true,
      label: i18n.t("calls.grid.date"),
      tdClass: "date-td",
      thClass: "date-th",
    },
    {
      key: "duration",
      sortable: true,
      label: i18n.t("calls.grid.duration"),
      tdClass: "duration-td",
      thClass: "duration-th",
    },
    {
      key: "direction",
      sortable: true,
      label: i18n.t("calls.grid.direction"),
      tdClass: "direction-td",
      thClass: "direction-th",
    },
    {
      key: "phone",
      sortable: true,
      label: i18n.t("calls.grid.phone"),
      tdClass: "phone-td",
      thClass: "phone-th",
    },
    {
      key: "estimationValue",
      sortable: true,
      label: i18n.t("calls.grid.estimationValue"),
      tdClass: "estimationValue-td",
      thClass: "estimationValue-th",
    },
    {
      key: "estimationDate",
      sortable: true,
      label: i18n.t("calls.grid.estimationDate"),
      tdClass: "estimationDate-td",
      thClass: "estimationDate-th",
    },
    {
      key: "leadDecision",
      sortable: false,
      label: "Возврат",
      tdClass: "leadDecision-td",
      thClass: "leadDecision-th",
    },
    {
      key: "hasComments",
      sortable: false,
      label: "",
      tdClass: "hasComments-td",
      thClass: "hasComments-th",
    },
    {
      key: "sttState",
      label: "Распознавание",
      sortable: false,
      tdClass: "sttState-td",
      thClass: "sttState-th",
    },
    {
      key: "action",
      label: "",
      sortable: false,
      tdClass: "action-td",
      thClass: "action-th",
    },
  ];

  private selectedColumns: string[] = [];

  private ellipsis: IconDefinition = faEllipsisV;
  private comment: IconDefinition = faCommentAlt;
  private showCallDetails = false;

  private callsListDataIsLoaded = false;

  private showCloseCallDetailConfirmation = false;

  private selectedCallId: number = null;

  get isBusy() {
    return getStore().getters.isCallsDataLoading;
  }

  async sortingChanged(ctx: SortDirectionModel) {
    this.sortBy = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;
    this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST_SORT, {
      sort: ctx.sortBy,
      direction: ctx.sortDesc ? "desc" : "asc",
    });
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST);
  }

  async mounted() {
    await this.refresh();
    const searchParams: any = decodeURI(window.location.search)
      .replace("?", "")
      .split("&")
      .map((param) => param.split("="))
      .reduce((values, [key, value]) => {
        values[key] = value;
        return values;
      }, {});
    if (searchParams.callId) {
      await this.handleRowClick({
        id: searchParams.callId,
      });
    }
  }

  async refresh() {
    this.selectedColumns = this.$store.getters.getCallsSelectedGridColumns;
    this.sortBy = (
      this.$store.getters.getCallsSortValue as CallsListSortModel
    ).sort;
    this.sortDesc = this.getSortDesc();
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST);
  }

  get listItems(): CallEntityModel[] {
    return this.$store.getters.getCallsList;
  }

  getSortDesc(): boolean {
    const direction = (
      this.$store.getters.getCallsSortValue as CallsListSortModel
    ).direction;
    return direction == "desc";
  }

  async handleRowClick(item: BaseCallModel | any) {
    // router
    //   .push({ path: "calls", query: { callId: item.id.toString() } })
    //   .catch((err) => {
    //     err;
    //   });
    this.setCurrentUrlCallIdParam(item.id);

    await this.$store.dispatch(StoreActionTypes.SET_SELECTED_CALL_CLEAR);
    this.selectedCallId = item.id;
    await this.$store.dispatch(StoreActionTypes.SET_SELECTED_CALL, item.id);
    this.showCallDetails = true;
  }

  setCurrentUrlCallIdParam(id: string | null) {
    const url = new URL(window.location.href);

    if (id !== null) {
      url.searchParams.set("callId", id);
    } else {
      url.searchParams.delete("callId");
    }

    history.pushState({}, null, url.href);
  }

  getDate(date: string) {
    return date ? utils.renderDateFromString(date) : "";
  }

  getDuration(duration: number) {
    return utils.renderDuration(duration * 1000);
  }

  getDirection(direction: CallDirection) {
    return utils.getDirection(direction);
  }

  handleOverlayClick(event: MouseEvent) {
    if (
      this.$refs.callsDetails &&
      (event.target as HTMLDivElement).contains(
        (this.$refs.callsDetails as CallsDetails).$el
      )
    ) {
      // router.push({ path: "calls", query: {} }).catch((err) => {
      //   err;
      // });
      this.handleCloseCallDetailsAction();
    }
  }

  closeOverlay() {
    this.showCloseCallDetailConfirmation = false;
    this.showCallDetails = false;
    this.setCurrentUrlCallIdParam(null);
    this.refresh();
  }

  handleCallsSelectedGridColumnsSelectChange(selectedColumns: string[]) {
    this.selectedColumns = selectedColumns;
  }

  get getSelectedColumns() {
    const selectedColumns = this.selectedColumns;
    const anyEntityHasLeadDecision = this.listItems.find((i) =>
      Object.hasOwn(i.data, `leadDecision`)
    );
    if (anyEntityHasLeadDecision) {
      selectedColumns.push("leadDecision");
    }

    const columns = this.fields.filter((item) => {
      if (item.key == "action") {
        return true;
      } else {
        return selectedColumns.indexOf(item.key) >= 0;
      }
    });

    return columns;
  }

  get hasProjects(): boolean {
    return (
      (this.$store.getters.getCallsProjectsList as BaseEntityModel[]).length > 0
    );
  }

  handleCreateNewProjectsBtnClick() {
    this.$router.push({ name: "projectCreateStepOne" });
  }

  handleCallsDataLoading(dataIsLoading: boolean) {
    this.callsListDataIsLoaded = !dataIsLoading;
  }

  syncSelectedCallId(call: CallModel) {
    if (call) {
      this.selectedCallId = call.id;
    }
  }

  getRowClass(item: BaseCallModel, type: string): string {
    if (!item || type !== "row") return;

    if (item.id == this.selectedCallId) {
      return "table-active";
    }

    return "";
  }

  get hasNotSavedComment(): boolean {
    return this.$store.getters.getCommentsHasNotSavedComment;
  }

  get atLeastOneEstimationchecked(): boolean {
    return (
      (
        this.$store.getters
          .getSelectedCallCurrentEstimations as CallCriterionEstimateModel[]
      ).length > 0
    );
  }

  get callRated(): boolean {
    return this.$store.getters.getSelectedCallRated;
  }

  hasUnsavedData(): boolean {
    if (this.callRated) {
      return this.hasNotSavedComment;
    } else {
      return this.hasNotSavedComment || this.atLeastOneEstimationchecked;
    }
  }

  handleCloseCallDetailsAction() {
    if (this.hasUnsavedData()) {
      this.showCloseCallDetailConfirmation = true;
    } else {
      this.closeOverlay();
    }
  }

  closeCallDetailConfirmationCancel() {
    this.showCloseCallDetailConfirmation = false;
  }

  getCloseCallDetailConfirmationText(): string {
    let text = "";

    if (this.hasNotSavedComment) {
      text += i18n.t("calls.confirmation.comment");
    }

    if (this.atLeastOneEstimationchecked) {
      text += i18n.t("calls.confirmation.estimation");
    }

    return `${text} ${i18n.t("calls.confirmation.common")}`;
  }
}
