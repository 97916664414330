import Vue from "vue";
import { PropType } from "vue";

export const IGridFooter = Vue.extend({
  props: {
    getTotal: {
      type: Number,
      required: true,
    },
    getTotalDuration: {
      type: Number,
      required: false,
    },
    getCurrentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    changePageQuantity: {
      type: Function as PropType<(selected: string) => void>,
      required: true,
    },
    changePage: {
      type: Function as PropType<(page: number) => void>,
      required: true,
    },
  },
});
