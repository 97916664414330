
import Component from "vue-class-component";
import Vue, { PropType } from "vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import SelectProjectsMultiple from "@/components/select/projectsMultiply/SelectProjectsMultiple.vue";
import {
  BaseEntityModel,
  DateIntervalsType,
  ReportsListDateFilterModel,
} from "@/services/types";
import { IconDefinition, faMinus } from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";
import SelectDateInterval from "@/components/select/dateInterval/SelectDateInterval.vue";
import * as utils from "@/services/utils";

export const ReportGridFilterProps = Vue.extend({
  props: {
    dateFilterListener: {
      required: true,
      type: Function as PropType<(request: ReportsListDateFilterModel) => void>,
    },
  },
});

@Component({
  components: {
    ActionButton,
    SelectProjectsMultiple,
    SelectDateInterval,
  },
  watch: {
    "$store.getters.getReportsSelectedProjectsList":
      "handleSelectedProjectsListChange",
    "$store.getters.getReportsFilterValue": "handleReportsFilterChange",
  },
})
export default class GridFilter extends ReportGridFilterProps {
  private componentClass = "GridFilter";

  private dateFilterValues: ReportsListDateFilterModel = {
    from: null,
    to: null,
  };

  private projectsSelectedItems: BaseEntityModel[] = [];

  private dividerIcon: IconDefinition = faMinus;

  private selectDateIntervalValue: DateIntervalsType = "week";

  async mounted() {
    /* if (this.savedFilters && (this.savedFilters.dates || this.savedFilters.projects)) {
            await this.setSavedFilters();
            return;
        } */
    this.dateFilterValues = this.$store.getters
      .getReportsFilterValue as ReportsListDateFilterModel;
    this.projectsSelectedItems = this.$store.getters
      .getReportsSelectedProjectsList as BaseEntityModel[];
    this.selectDateIntervalValue = this.$store.getters.getReportsDateInterval;
  }

  async setSavedFilters() {
    const filters = this.savedFilters;
    this.dateFilterValues = filters.dates;
    this.projectsSelectedItems = filters.projects;
    this.selectDateIntervalValue = "custom";
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_DATE_INTERVAL,
      this.selectDateIntervalValue
    );
    await this.dateFilterListener(this.dateFilterValues);
  }

  get getOutLineStatus() {
    return this.selectedProjectsList.length < this.projectsList.length
      ? true
      : false;
  }

  get projectsList() {
    return this.$store.getters.getReportsProjectsList as BaseEntityModel[];
  }

  get selectedProjectsList() {
    return this.$store.getters
      .getReportsSelectedProjectsList as BaseEntityModel[];
  }

  handleProjectsSelectEvent(selectedItems: BaseEntityModel[]) {
    this.projectsSelectedItems = selectedItems;
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_SELECTED_PROJECTS_LIST,
      this.projectsSelectedItems
    );
    this.$store.dispatch(StoreActionTypes.SET_REPORTS_RENDER_LIST);
  }

  cancelBtnListener() {
    this.projectsSelectedItems = this.projectsList;
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_SELECTED_PROJECTS_LIST,
      this.projectsSelectedItems
    );
    this.$store.dispatch(StoreActionTypes.SET_REPORTS_RENDER_LIST);
  }

  get showSetDefaultFiltersValueBtn(): boolean {
    return this.projectsSelectedItems.length < this.projectsList.length
      ? true
      : false;
  }

  handleSelectedProjectsListChange(list: BaseEntityModel[]) {
    if (list.length != this.projectsSelectedItems.length) {
      this.projectsSelectedItems = list;
    }
  }

  handleReportsFilterChange(filter: ReportsListDateFilterModel) {
    const result: ReportsListDateFilterModel = {
      from:
        this.dateFilterValues.from != filter.from
          ? filter.from
          : this.dateFilterValues.from,
      to:
        this.dateFilterValues.to != filter.to
          ? filter.to
          : this.dateFilterValues.to,
    };

    this.dateFilterValues = result;
  }

  async handleDatePickerChange() {
    this.selectDateIntervalValue = "custom";
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_DATE_INTERVAL,
      this.selectDateIntervalValue
    );
    await this.dateFilterListener(this.dateFilterValues);
  }

  async handleSelectDateIntervalChange(selected: string) {
    this.selectDateIntervalValue = selected as DateIntervalsType;
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_DATE_INTERVAL,
      this.selectDateIntervalValue
    );

    let dateFrom = "";

    if (this.selectDateIntervalValue == "day") {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      dateFrom = utils.getFormattedDate(date);
    }
    if (this.selectDateIntervalValue == "week") {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      dateFrom = utils.getFormattedDate(date);
    }
    if (this.selectDateIntervalValue == "month") {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      dateFrom = utils.getFormattedDate(date);
    }

    const request: ReportsListDateFilterModel = {
      from: dateFrom,
      to: utils.getFormattedDate(new Date()),
    };

    await this.dateFilterListener(request);
  }

  get savedFilters() {
    return this.$store.getters.getSavedFilters.reports;
  }

  async saveFiltersBtnListener() {
    await this.$store.dispatch(StoreActionTypes.SET_SAVED_FILTERS, {
      reports: {
        dates: this.dateFilterValues,
        projects: this.projectsSelectedItems,
      },
    });
  }

  async clearSavedFiltersBtnListener() {
    await this.$store.dispatch(StoreActionTypes.SET_SAVED_FILTERS, {
      reports: {},
    });
    this.projectsSelectedItems = [];
    this.dateFilterValues = this.$store.getters
      .getReportsFilterValue as ReportsListDateFilterModel;
  }
}
